import React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '@components/layout'
import SEO from '@components/seo'
import Sidebar from '@components/sidebar'
import LinkButton from '@components/linkbutton'

const StudyWorks = ({ data, location }) => {
    const siteTitle = data.siteMetaData.siteMetadata.title
    const studyWorks = data.study.nodes

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title="Study Works" />
            <Sidebar />
            <h1 className="section-title">デザイン研究</h1>
            <p className="section-header italic">STUDY WORKS</p>
            <section className="study-page-container">
                {studyWorks.map((studyWork, index) => {
                    const title = studyWork.frontmatter.title
                    const sub = studyWork.frontmatter.sub
                    const info = studyWork.frontmatter.info
                    const summary = studyWork.frontmatter.summary
                    const studyThumbnail = studyWork.frontmatter.thumbnail

                    return (
                        <article className="study-page-item" key={`${title}-${index}`}>
                            <Link to={studyWork.fields.slug} itemProp="url" className="">
                                {studyThumbnail ? (
                                    <GatsbyImage image={getImage(studyThumbnail.childImageSharp)} alt="" loading={index === 0 ? `eager` : `lazy`} className="front-study-work-thumbnail" />
                                ) : (
                                    <div className="front-study-work-thumbnail temp-front-study-work-thumbnail" />
                                )}
                            </Link>
                            <section className="study-page-text">
                                <h1>{title}</h1>
                                <h6>{sub}</h6>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: summary,
                                    }}
                                    itemProp="description"
                                />
                                <div className="text-bottom">
                                    <p className="study-info">{info}</p>
                                    <Link to={studyWork.fields.slug} itemProp="url" className="learn-more">
                                        詳しく見る
                                    </Link>
                                </div>
                            </section>
                        </article>
                    )
                })}
            </section>
            <LinkButton to="/" text="トップへ戻る" />
        </Layout>
    )
}
export default StudyWorks

export const pageQuery = graphql`
    query {
        study: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/study-works/" } }, sort: { fields: [frontmatter___date], order: DESC }) {
            nodes {
                fields {
                    slug
                }
                frontmatter {
                    title
                    sub
                    info
                    summary
                    thumbnail {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
        siteMetaData: site {
            siteMetadata {
                title
            }
        }
    }
`
