import React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '@components/layout'
import SEO from '@components/seo'
import Sidebar from '@components/sidebar'
import LinkButton from '@components/linkbutton'

const otherWorks = ({ data, location }) => {
    const siteTitle = data.siteMetaData.siteMetadata.title
    const otherWorks = data.other.nodes
    const tags = data.tags.nodes

    const sorting = e => {
        const tagName = e.currentTarget.textContent
        const container = document.getElementById('page-container')
        let tags = document.getElementsByClassName('sorting-li')
        let showPosts = document.getElementsByClassName('otherwork-post')

        for (var i = tags.length - 1; i >= 0; i--) {
            tags[i].classList.remove('active')
        }
        e.currentTarget.classList.add('active')

        container.style.opacity = '0'

        setTimeout(function () {
            //fade in
            if (tagName === 'すべて') {
                for (let i = 0; i < showPosts.length; i++) {
                    showPosts[i].style.display = 'block'
                }
            } else {
                for (let i = 0; i < showPosts.length; i++) {
                    if (showPosts[i].dataset.category === tagName) {
                        showPosts[i].style.display = 'block'
                    } else {
                        showPosts[i].style.display = 'none'
                    }
                }
            }

            container.style.opacity = '100'
        }, 200)
    }

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title="other Works" />
            <Sidebar />
            <h1 className="section-title">グラフィック</h1>
            <p className="section-header italic">GRAPHIC DESIGN</p>
            <div className="other-post-category">
                <ul className="sorting">
                    <li className="sorting-li active" onClick={sorting} onKeyDown={sorting}>
                        すべて
                    </li>
                    {tags.map((tag, index) => {
                        return (
                            <li className="sorting-li" key={`${tag.childMarkdownRemark.frontmatter.name}-${index}`} onClick={sorting} onKeyDown={sorting}>
                                {tag.childMarkdownRemark.frontmatter.name}
                            </li>
                        )
                    })}
                </ul>
            </div>
            <section className="other-page-container" id="page-container">
                {otherWorks.map((otherWork, index) => {
                    const title = otherWork.frontmatter.title
                    const otherThumbnail = otherWork.frontmatter.thumbnail

                    return (
                        <article className="otherwork-post" key={otherWork.frontmatter.title} data-category={otherWork.frontmatter.tags && otherWork.frontmatter.tags}>
                            <Link to={otherWork.fields.slug} itemProp="url">
                                {otherThumbnail ? (
                                    <GatsbyImage image={getImage(otherThumbnail.childImageSharp)} alt="" loading={index === 0 ? `eager` : `lazy`} className="other-work-thumbnail" />
                                ) : (
                                    <div className="other-work-thumbnail temp-other-work-thumbnail" />
                                )}
                                <p>{title}</p>
                            </Link>
                        </article>
                    )
                })}
            </section>
            <LinkButton to="/" text="トップへ戻る" />
        </Layout>
    )
}
export default otherWorks

export const pageQuery = graphql`
    query pageQuery {
        other: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/other-works/" } }, sort: { fields: [frontmatter___date], order: DESC }) {
            nodes {
                fields {
                    slug
                }
                frontmatter {
                    title
                    tags
                    thumbnail {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
        tags: allFile(filter: { sourceInstanceName: { eq: "tags" } }) {
            nodes {
                childMarkdownRemark {
                    frontmatter {
                        name
                    }
                }
            }
        }
        siteMetaData: site {
            siteMetadata {
                title
            }
        }
    }
`
