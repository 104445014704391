import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@components/layout'
import Sidebar from '@components/sidebar'
import SEO from '@components/seo'

const Contact = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title="Contact" />
            <Sidebar />
            <h1 className="section-title">お問い合わせ</h1>
            <p className="section-header italic">CONTACT</p>
            <p className="contact-text">プロジェクト案件などの依頼、その他のお問い合わせは、以下のフォームからご連絡ください。</p>
            <section className="contact-section">
                <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field" action="thanks">
                    <div className="form-inner">
                        <input type="hidden" name="form-name" value="contact" />
                        <input type="hidden" name="bot-field" />
                        <div className="form-content form-content-left">
                            <div className="form-group">
                                <label>
                                    <input type="text" className="form-control" id="name" name="name" maxlength="30" minlength="2" placeholder="名前" required autocomplete="name" />
                                </label>
                            </div>
                            <div className="form-group">
                                <label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        name="email"
                                        placeholder="メールアドレス"
                                        pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                                        required
                                        autocomplete="email"
                                    />
                                </label>
                            </div>
                            <div className="form-group">
                                <label>
                                    <input type="text" className="form-control" id="title" name="title" placeholder="件名" required />
                                </label>
                            </div>
                        </div>

                        <div className="form-content">
                            <div className="form-group form-textarea">
                                <label>
                                    <textarea className="form-control" id="contact" name="content" rows="8" placeholder="問い合わせ内容" required></textarea>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="form-submit">
                        <button type="submit">送信する</button>
                    </div>
                </form>
            </section>
        </Layout>
    )
}
export default Contact

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`
