import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Layout from '@components/layout'
import SEO from '@components/seo'
import Sidebar from '@components/sidebar'
import LinkButton from '@components/linkbutton'

const BlogPostTemplate = ({ data, location }) => {
    const siteTitle = data.siteMetaData.siteMetadata.title
    const post = data.markdownRemark
    const title = post.frontmatter.title
    const sub = post.frontmatter.sub
    const info = post.frontmatter.info
    const concept = post.html
    const path = post.fileAbsolutePath
    const galleries = post.frontmatter.galleryImages
    let result = path.includes('study-works')
    let link = ''
    if (result === true) {
        link = '/study-works'
    } else {
        link = '/other-works'
    }

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title={title} />
            <Sidebar />
            <div className="works-single-container">
                <h1 className="post-headline">{title}</h1>
                <p>{sub}</p>
                <p className="single-page-info">{info}</p>
                <article className="works-post" itemScope itemType="http://schema.org/Article">
                    <section className="works-post-item">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: concept.replace(/\\/g, '<br>'),
                            }}
                        />
                    </section>
                    <section className="gallery">
                        {galleries && galleries.map((gallery, index) => <GatsbyImage image={getImage(gallery.childImageSharp)} key={`${title}-${index}`} alt="" loading={index === 0 ? `eager` : `lazy`} className="gallery-img" />)}
                    </section>
                </article>
            </div>
            <LinkButton to={link} text="一覧へ戻る" />
        </Layout>
    )
}
export default BlogPostTemplate

export const pageQuery = graphql`
    query BlogPostBySlug($id: String!) {
        markdownRemark(id: { eq: $id }) {
            id
            html
            fileAbsolutePath
            frontmatter {
                title
                sub
                info
                tags
                galleryImages {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
        }
        siteMetaData: site {
            siteMetadata {
                title
            }
        }
    }
`
