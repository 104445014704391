import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '@components/layout'
import SEO from '@components/seo'
import Sidebar from '@components/sidebar'
import LinkButton from '@components/linkbutton'

const AboutPage = ({ data, location }) => {
    const siteTitle = data.siteMetaData.siteMetadata.title
    const biography = data.bio.nodes[0].frontmatter
    const isDummyProf = biography.profile_pic.base === 'dummy-pic.png'

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title="Biography" />
            <Sidebar />
            <h1 className="section-title">プロフィール</h1>
            <p className="section-header italic">BIOGRAPHY</p>
            <section className="biography-container">
                <div className="biography-top">
                    <div className="biography-inner">
                        <h1 className="biography-name">{biography.jp_name}</h1>
                        <p className="italic">{biography.en_name}</p>
                        <h4 className="prof-title">{biography.en_title}</h4>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: data.bio.nodes[0].html.replace(/\\/g, '<br>'),
                            }}
                        />
                    </div>
                    {isDummyProf ? (
                        <GatsbyImage image={getImage(biography.profile_pic.childImageSharp)} alt="" className="profile-img dummy-pic" />
                    ) : (
                        <GatsbyImage image={getImage(biography.profile_pic.childImageSharp)} alt="" className="profile-img" />
                    )}
                </div>
                <hr />
                <div className="biography-bottom">
                    <h6 className="biography-head">展示 / ワークショップ</h6>
                    <p
                        className="biography-text"
                        dangerouslySetInnerHTML={{
                            __html: biography.exhibition.replace(/\\/g, '<br>'),
                        }}
                    />
                    <h6 className="biography-head">論文</h6>
                    <p
                        className="biography-text"
                        dangerouslySetInnerHTML={{
                            __html: biography.paper.replace(/\\/g, '<br>'),
                        }}
                    />
                </div>
            </section>
            <LinkButton to="/contact" text="Contact" />
        </Layout>
    )
}
export default AboutPage

export const pageQuery = graphql`
    query {
        bio: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/page/" } }) {
            nodes {
                fields {
                    slug
                }
                html
                frontmatter {
                    jp_name
                    en_name
                    title
                    en_title
                    exhibition
                    paper
                    profile_pic {
                        base
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
        siteMetaData: site {
            siteMetadata {
                title
            }
        }
    }
`
