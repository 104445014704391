import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const News = () => {
    const data = useStaticQuery(graphql`
        query {
            news: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/news/" } }, sort: { fields: [frontmatter___date], order: DESC }) {
                nodes {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        date(formatString: "YYYY/MM/DD")
                        thumbnail {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                    excerpt(truncate: true, pruneLength: 120)
                }
            }
        }
    `)
    const news = data.news.nodes[0]

    return (
        <div className="main-section news-section" id="news-section">
            <div className="section-title-holder">
                <h1 className="section-title">お知らせ</h1>
                <p className="section-header italic">NEWS</p>
            </div>
            <article className="news-container">
                <div className="news-left">
                    <h5 className="news-date">{news.frontmatter?.date}</h5>
                    <div className="news-content">
                        <h5>{news.frontmatter?.title}</h5>
                        <p className="news-excerpt">{news.excerpt && news.excerpt.replace(/\s+/g, '')}</p>
                        <Link to={news.fields.slug} className="learn-more news-learn-more">
                            詳しく見る
                        </Link>
                    </div>
                </div>
                {news?.frontmatter?.thumbnail ? <GatsbyImage image={getImage(news.frontmatter.thumbnail.childImageSharp)} alt="" className="news-thumbnail" /> : <div className="news-thumbnail temp-news-thumbnail" />}
                <Link to={news.fields.slug} className="learn-more news-learn-more-sp">
                    詳しく見る
                </Link>
            </article>
        </div>
    )
}
export default News
