import "@style/normalize.css"
import "@style/common.scss"
import "@style/single.scss"
import "@style/tablet.scss"
import "@style/sp.scss"

export function onInitialClientRender () {
    let height=window.innerHeight;
    document.documentElement.style.setProperty( '--vh', height/100 + 'px');
}

export function onRouteUpdate ({ location }) {
    if (location.pathname === "/contact" || location.pathname === "/contact/") { //なぜかこうなる
        ;
    } else {
        const footerH = document.getElementById('footer').clientHeight;
        const main = document.getElementById('main');
        main.style.minHeight = `calc( var(--vh) * 100 - ${footerH}px)`;
    }

    if (location.pathname === "/") {
        setTimeout(function() {
            document.getElementById('wrapper').style.opacity = '100';
        }, 300);
    } else {
        setTimeout(function() {
            document.getElementById('wrapper').style.opacity = '100';
        }, 150);
    }
}