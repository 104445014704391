import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import LinkButton from './linkbutton'

const Bio = () => {
    const data = useStaticQuery(graphql`
        query {
            bio: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/page/" } }) {
                nodes {
                    fields {
                        slug
                    }
                    frontmatter {
                        jp_name
                        en_name
                        title
                        en_title
                        summary
                    }
                }
            }
        }
    `)
    const biography = data.bio.nodes[0].frontmatter

    return (
        <div className="main-section bio-section" id="bio-section">
            <div className="section-title-holder">
                <h1 className="section-title">プロフィール</h1>
                <p className="section-header italic">BIOGRAPHY</p>
            </div>
            <section className="bio-container">
                <div className="bio-left">
                    <h1>{biography.jp_name}</h1>
                    <p className="italic bio-en-name">{biography.en_name}</p>
                </div>
                <div className="bio-right">
                    <h5 className="bio-title">{biography.en_title}</h5>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: biography.summary.replace(/\\/g, '<br>'),
                        }}
                    />
                </div>
            </section>
            <LinkButton to="/about" text="プロフィールを見る" />
        </div>
    )
}
export default Bio
