import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { createMedia } from '@artsy/fresnel'

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        sm: 0,
        md: 720,
        lg: 960,
        xl: 1192,
    },
})

const SlideShow = () => {
    const data = useStaticQuery(graphql`
        query slideshow {
            study: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/study-works/" } }, sort: { fields: [frontmatter___date], order: DESC }, limit: 5) {
                nodes {
                    fields {
                        slug
                    }
                    frontmatter {
                        date
                        thumbnail {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        top_landscape {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        top_portrait {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
            other: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/other-works/" } }, sort: { fields: [frontmatter___date], order: DESC }, limit: 5) {
                nodes {
                    fields {
                        slug
                    }
                    frontmatter {
                        date
                        thumbnail {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        top_landscape {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                        top_portrait {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
        }
    `)
    const temp1 = data.study.nodes
    const temp2 = data.other.nodes
    const posts = temp1.concat(temp2)

    // date time sorted
    posts.sort(function (a, b) {
        if (a.frontmatter.date > b.frontmatter.date) return -1
        if (a.frontmatter.date < b.frontmatter.date) return 1
        return 0
    })

    return (
        <div className="main-img-bg">
            <MediaContextProvider>
                <Media greaterThanOrEqual="lg">
                    {posts.slice(0, 5).map((post, index) => {
                        return (
                            <Link to={post.fields.slug} className="main-img-a" key={post.fields.slug}>
                                {post.frontmatter.top_landscape ? (
                                    <GatsbyImage image={getImage(post.frontmatter.top_landscape.childImageSharp)} alt="" loading={index === 0 ? `eager` : `lazy`} className="main-img" />
                                ) : (
                                    <GatsbyImage image={getImage(post.frontmatter.thumbnail.childImageSharp)} alt="" loading={index === 0 ? `eager` : `lazy`} className="main-img" />
                                )}
                            </Link>
                        )
                    })}
                </Media>
                <Media lessThan="lg">
                    {posts.slice(0, 5).map((post, index) => {
                        return (
                            <Link to={post.fields.slug} className="main-img-a" key={post.fields.slug}>
                                {post.frontmatter.top_portrait ? (
                                    <GatsbyImage image={getImage(post.frontmatter.top_portrait.childImageSharp)} alt="" loading={index === 0 ? `eager` : `lazy`} className="main-img" />
                                ) : (
                                    <GatsbyImage image={getImage(post.frontmatter.thumbnail.childImageSharp)} alt="" loading={index === 0 ? `eager` : `lazy`} className="main-img" />
                                )}
                            </Link>
                        )
                    })}
                </Media>
            </MediaContextProvider>
        </div>
    )
}
export default SlideShow

let count = 1
const slide = () => {
    if (window == undefined || window == null) return

    if (window.innerWidth >= 960) {
        // desktop
        const img = document.getElementsByClassName('main-img')
        const pagenation = document.getElementsByClassName('jp-pagenation')

        if (img.length !== 0) {
            if (count > img.length) {
                count = 1 //initialize
            }
            if (count !== 5) {
                img[count - 1].style.opacity = '0'
                img[count].style.opacity = '1'
                pagenation[count - 1].classList.remove('page-active')
                pagenation[count].classList.add('page-active')
            } else {
                img[count - 1].style.opacity = '0'
                img[0].style.opacity = '1'
                pagenation[count - 1].classList.remove('page-active')
                pagenation[0].classList.add('page-active')
            }
            count++
        } else {
            //do nothing
        }
    } else {
        // tablet & sp
        const img = document.getElementsByClassName('main-img')

        if (img.length !== 0) {
            if (count > img.length) {
                count = 1 //initialize
            }
            if (count !== 5) {
                img[count - 1].style.opacity = '0'
                img[count].style.opacity = '1'
            } else {
                img[count - 1].style.opacity = '0'
                img[0].style.opacity = '1'
            }
            count++
        } else {
            //do nothing
        }
    }
}
setInterval(slide, 2700)
