import React from 'react'
import { Link, graphql } from 'gatsby'
import { createMedia } from '@artsy/fresnel'
import Layout from '@components/layout'
import SEO from '@components/seo'
import scrollTo from 'gatsby-plugin-smoothscroll'
import SlideShow from '@components/slideshow'
import Study from '@components/study'
import Other from '@components/other'
import Bio from '@components/bio'
import News from '@components/news'
import Icon from '@assets/logo.svg'
import Scroll from '@assets/scroll.svg'

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        sm: 0,
        md: 720,
        lg: 960,
        xl: 1192,
    },
})

const BlogIndex = ({ data, location }) => {
    const siteTitle = data.siteMetaData.siteMetadata.title
    // const str = data.bio.nodes[0].frontmatter.title
    // const title1 = str.split("　")[0] //デザイン研究者
    // const title2 = str.split("　")[1] //グラフィックデザイナー
    const title1 = `Design Researcher`
    const title2 = `Graphic Designer`

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title="Front Page" />
            <MediaContextProvider>
                <div className="first-view" id="first-view">
                    <Media lessThan="md">
                        <Link to="/" className="">
                            <Icon className="header-logo-sp" />
                        </Link>
                    </Media>
                    <Media greaterThanOrEqual="lg">
                        <ul className="top-pagenation">
                            <li key="0" className="jp-pagenation page-active">
                                壱
                            </li>
                            <li key="1" className="jp-pagenation">
                                弐
                            </li>
                            <li key="2" className="jp-pagenation">
                                参
                            </li>
                            <li key="3" className="jp-pagenation">
                                四
                            </li>
                            <li key="4" className="jp-pagenation">
                                伍
                            </li>
                        </ul>
                    </Media>
                    <Media greaterThanOrEqual="md">
                        <ul className="top-only-menu">
                            <li onClick={() => scrollTo('#first-view')}>トップ</li>
                            <li onClick={() => scrollTo('#study-section')}>デザイン研究</li>
                            <li onClick={() => scrollTo('#other-section')}>グラフィック</li>
                            <li onClick={() => scrollTo('#bio-section')}>プロフィール</li>
                            <li onClick={() => scrollTo('#news-section')}>お知らせ</li>
                        </ul>
                        <h1 className="top-title">{title1}</h1>
                        <h1 className="top-title2">{title2}</h1>
                    </Media>
                    <Media lessThan="md">
                        <div className="top-title-container">
                            <h1 className="top-title-sp">{title2}</h1>
                            <h1 className="top-title-sp">{title1}</h1>
                            <h1 className="top-title-sp">{data.bio.nodes[0].frontmatter.en_name}</h1>
                        </div>
                    </Media>
                    <SlideShow />
                    <Scroll onClick={() => scrollTo('#study-section')} className="scroll-down" />
                </div>
                <Study />
                <Other />
                <Bio />
                <News />
            </MediaContextProvider>
        </Layout>
    )
}
export default BlogIndex

if (typeof window !== 'undefined') {
    window.addEventListener('scroll', function () {
        if (typeof document.getElementsByClassName('scroll-down')[0] !== 'undefined') {
            const arrow = document.getElementsByClassName('scroll-down')[0]
            if (20 < window.scrollY) {
                arrow.style.opacity = '0'
            } else {
                arrow.style.opacity = '1'
            }
        }
    })
}

export const pageQuery = graphql`
    query {
        bio: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/page/" } }) {
            nodes {
                frontmatter {
                    jp_name
                    en_name
                    title
                }
            }
        }
        siteMetaData: site {
            siteMetadata {
                title
            }
        }
    }
`
