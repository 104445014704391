import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@components/layout'
import SEO from '@components/seo'
import Sidebar from '@components/sidebar'
import LinkButton from '@components/linkbutton'

const NewsPostTemplate = ({ data, location }) => {
    const siteTitle = data.siteMetaData.siteMetadata.title
    const post = data.markdownRemark
    const title = post.frontmatter.title
    const info = post.frontmatter.info
    const date = post.frontmatter.date
    const html = post.html

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title={title} />
            <Sidebar />
            <h1 className="section-title">お知らせ</h1>
            <p className="section-header italic">NEWS</p>
            <section className="news-single-section">
                <p className="news-date">{date}</p>
                <section className="news-post">
                    <h1 className="news-post-title">{title}</h1>
                    <p className="news-single-info">{info}</p>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: html.replace(/\\/g, '<br>'),
                        }}
                    />
                </section>
            </section>
            <LinkButton to="/" text="トップへ戻る" />
        </Layout>
    )
}
export default NewsPostTemplate

export const pageQuery = graphql`
    query NewsPostBySlug($id: String!) {
        markdownRemark(id: { eq: $id }) {
            id
            html
            fileAbsolutePath
            frontmatter {
                title
                info
                date(formatString: "YYYY/MM/DD")
            }
        }
        siteMetaData: site {
            siteMetadata {
                title
            }
        }
    }
`
