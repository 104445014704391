import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Icon from '@assets/logo.svg'
import { createMedia } from '@artsy/fresnel'

const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        sm: 0,
        md: 720,
        lg: 960,
        xl: 1192,
    },
})

const Sidebar = () => {
    const data = useStaticQuery(graphql`
        query Sidebar {
            news: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/news/" } }, sort: { fields: [frontmatter___date], order: DESC }, limit: 1) {
                nodes {
                    fields {
                        slug
                    }
                }
            }
        }
    `)
    const newsPath = data.news.nodes[0].fields.slug

    return (
        <MediaContextProvider>
            <Media greaterThanOrEqual="md">
                <nav className="sidebar" id="sidebar" onClick={sidebar} onKeyDown={sidebar}>
                    <div className="box-lid-icon">MENU</div>
                    <Link to="/">
                        <Icon className="sidebar-logo" />
                    </Link>
                    <ul className="outer-ul">
                        <li>
                            <Link to="/">トップページ</Link>
                        </li>
                        <li>
                            <Link to="/study-works">デザイン研究</Link>
                        </li>
                        <li>
                            <Link to="/other-works">グラフィック</Link>
                        </li>
                        <li>
                            <Link to="/about">プロフィール</Link>
                        </li>
                        <li>
                            <Link to={newsPath}>ニュース</Link>
                        </li>
                    </ul>
                    <Link to="/contact" className="sidebar-contact">
                        Contact
                    </Link>
                </nav>
            </Media>
        </MediaContextProvider>
    )
}
export default Sidebar

const sidebar = e => {
    const menu = e.currentTarget
    if (window.innerWidth < 960 && window.innerWidth > 720) {
        if (menu.style.left === '-23vw' || menu.style.left === '') {
            menu.style.left = '0'
            menu.style.backgroundColor = '#E5E3DE'
        } else {
            menu.style.left = '-23vw'
            menu.style.backgroundColor = '#8E8B83'
        }
    }
}
if (typeof window !== 'undefined') {
    if (document.getElementById('sidebar') !== null) {
        document.getElementById('sidebar').addEventListener('click touchend', sidebar)
    }
}
