import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@components/layout'
import SEO from '@components/seo'
import Sidebar from '@components/sidebar'
import LinkButton from '@components/linkbutton'

const Thanks = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title="Thanks" />
            <Sidebar />
            <h1 className="section-title">お問い合わせ</h1>
            <p className="section-header italic">CONTACT</p>
            <section className="contact-section">
                <h4 className="thanks-h">問い合わせありがとうございました</h4>
                <p className="thanks-p italic">THANK YOU FOR YOUR INQUIRY</p>
            </section>
            <LinkButton to="/" text="トップへ戻る" />
        </Layout>
    )
}
export default Thanks

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`
