import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import LinkButton from './linkbutton'

const Study = () => {
    const data = useStaticQuery(graphql`
        query {
            study: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/study-works/" } }, sort: { fields: [frontmatter___date], order: DESC }, limit: 2) {
                nodes {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        sub
                        thumbnail {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                    excerpt(truncate: true, pruneLength: 90)
                }
            }
        }
    `)
    const studyWorks = data.study.nodes

    return (
        <div className="main-section study-section" id="study-section">
            <div className="section-title-holder">
                <h1 className="section-title">デザイン研究</h1>
                <p className="section-header italic">DESIGN PROJECTS</p>
            </div>
            <section className="study-container">
                {studyWorks.map(studyWork => {
                    const title = studyWork.frontmatter.title
                    const studyThumbnail = studyWork.frontmatter.thumbnail
                    const sub = studyWork.frontmatter.sub

                    return (
                        <article className="post-list-item" key={studyWork.fields.slug}>
                            <Link to={studyWork.fields.slug} itemProp="url">
                                {studyThumbnail ? (
                                    <GatsbyImage image={getImage(studyThumbnail.childImageSharp)} alt="" className="front-study-work-thumbnail" />
                                ) : (
                                    <div className="front-study-work-thumbnail temp-front-study-work-thumbnail" />
                                )}
                            </Link>
                            <section className="study-text">
                                <h1>{title}</h1>
                                <h6>{sub}</h6>
                                <Link to={studyWork.fields.slug} itemProp="url" className="learn-more study-learn-more">
                                    詳しく見る
                                </Link>
                            </section>
                        </article>
                    )
                })}
            </section>
            <LinkButton to="/study-works" text="デザイン研究を見る" />
        </div>
    )
}
export default Study
