import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import LinkButton from './linkbutton'

const Other = () => {
    const data = useStaticQuery(graphql`
        query {
            other: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/other-works/" } }, sort: { fields: [frontmatter___date], order: DESC }, limit: 3) {
                nodes {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        thumbnail {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
        }
    `)
    const otherWorks = data.other.nodes

    return (
        <div className="main-section other-section" id="other-section">
            <div className="section-title-holder">
                <h1 className="section-title">グラフィック</h1>
                <p className="section-header italic">GRAPHIC DESIGN</p>
            </div>
            <section className="other-container">
                {otherWorks.map(otherWork => {
                    const title = otherWork.frontmatter.title
                    const otherThumbnail = otherWork.frontmatter.thumbnail

                    return (
                        <article className="post-list-item" key={title}>
                            <Link to={otherWork.fields.slug} itemProp="url">
                                {otherThumbnail ? <GatsbyImage image={getImage(otherThumbnail.childImageSharp)} alt="" className="other-work-thumbnail" /> : <div className="other-work-thumbnail temp-other-work-thumbnail" />}
                                <p>{title}</p>
                            </Link>
                        </article>
                    )
                })}
            </section>
            <LinkButton to="/other-works" text="グラフィックを見る" />
        </div>
    )
}
export default Other
